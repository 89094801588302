var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Nouveau","dense":""},on:{"change":_vm.newproduct_click},model:{value:(_vm.newproduct),callback:function ($$v) {_vm.newproduct=$$v},expression:"newproduct"}}),_c('v-spacer'),_c('v-radio-group',{attrs:{"row":"","mandatory":"","dense":""},on:{"change":function($event){_vm.cs++}},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}},[_c('v-radio',{attrs:{"label":"Produits","value":0}}),_c('v-radio',{attrs:{"label":"Service","value":1}})],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.newproduct)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.item.fact_type == 4 || _vm.item.fact_type == 3
                      ? 'Code'
                      : 'Ref',"dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}})],1):_vm._e(),(_vm.newproduct)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-text-field',{ref:"label",attrs:{"autocomplete":"off","dense":"","label":"Produit","rules":[function (v) { return !!v || 'Produit obligatoire'; }]},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.label),callback:function ($$v) {_vm.$set(_vm.editedItem, "label", $$v)},expression:"editedItem.label"}})],1):_vm._e(),(!_vm.newproduct)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"10"}},[_c('cursorselect',{key:_vm.cs,attrs:{"Qsearch":_vm.products_cursor,"Qresp":'products_cursor',"value":_vm.produit_id,"text_fields":['code', 'label'],"variableadd":_vm.variableadd,"whereadd":_vm.whereadd,"label":'Produit'},on:{"change":_vm.produit_change}})],1):_vm._e(),(!_vm.newproduct && _vm.spec)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"1"}},[_c('v-checkbox',{attrs:{"label":"Tous","dense":""},on:{"change":function($event){_vm.cs++}},model:{value:(_vm.allproduct),callback:function ($$v) {_vm.allproduct=$$v},expression:"allproduct"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-text-field',{ref:"pu",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":_vm.item.fact_type == 4 || _vm.item.fact_type == 3
                      ? 'P.U.'
                      : 'Montant',"rules":[
                    function (v) { return !!v ||
                      (_vm.item.fact_type == 4 || _vm.item.fact_type == 3
                        ? 'P.U.'
                        : 'Montant') + ' obligatoire'; },
                    function (v) { return v > 0 || 'Valeur incorrecte '; },
                    function (v) { return !v ||
                      _vm.qte_max == 0 ||
                      parseFloat(v) <= parseFloat(_vm.qte_max) ||
                      'Quantité Max ' + _vm.qte_max; } ],"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.pu),callback:function ($$v) {_vm.$set(_vm.editedItem, "pu", _vm._n($$v))},expression:"editedItem.pu"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":"Quantité","rules":[
                    function (v) { return !!v || 'Quantité obligatoire'; },
                    function (v) { return v > 0 || 'Valeur incorrecte '; } ],"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", _vm._n($$v))},expression:"editedItem.qte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"tva",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":"TVA","rules":[function (v) { return !v || v > 0 || 'Valeur incorrecte'; }],"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.tva_tx),callback:function ($$v) {_vm.$set(_vm.editedItem, "tva_tx", _vm._n($$v))},expression:"editedItem.tva_tx"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"12"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fill-height":""}},[(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }