<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-checkbox
              label="Nouveau"
              v-model="newproduct"
              dense
              @change="newproduct_click"
            ></v-checkbox>
            <v-spacer> </v-spacer>
            <v-radio-group v-model="service" @change="cs++" row mandatory dense>
              <v-radio label="Produits" :value="0"></v-radio>
              <v-radio label="Service" :value="1"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="2" v-if="newproduct">
                  <v-text-field
                    v-model="editedItem.code"
                    :label="
                      item.fact_type == 4 || item.fact_type == 3
                        ? 'Code'
                        : 'Ref'
                    "
                    dense
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8" v-if="newproduct">
                  <v-text-field
                    autocomplete="off"
                    ref="label"
                    dense
                    v-model="editedItem.label"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="10" v-if="!newproduct">
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    :whereadd="whereadd"
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>

                <v-col cols="12" sm="6" md="1" v-if="!newproduct && spec">
                  <v-checkbox
                    label="Tous"
                    v-model="allproduct"
                    dense
                    @change="cs++"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    ref="pu"
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pu"
                    :label="
                      item.fact_type == 4 || item.fact_type == 3
                        ? 'P.U.'
                        : 'Montant'
                    "
                    :rules="[
                      (v) =>
                        !!v ||
                        (item.fact_type == 4 || item.fact_type == 3
                          ? 'P.U.'
                          : 'Montant') + ' obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                      (v) =>
                        !v ||
                        qte_max == 0 ||
                        parseFloat(v) <= parseFloat(qte_max) ||
                        'Quantité Max ' + qte_max,
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    label="Quantité"
                    :rules="[
                      (v) => !!v || 'Quantité obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="tva"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    label="TVA"
                    :rules="[(v) => !v || v > 0 || 'Valeur incorrecte']"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_FACT_DETAIL from "../graphql/Facture/CREATE_FACT_DETAIL.gql";
import UPDATE_FACT_DETAIL from "../graphql/Facture/UPDATE_FACT_DETAIL.gql";
import PRODUCTS_CURSOR from "../graphql/Mouvment/PRODUCTS_CURSOR.gql";
import { spec } from "print/data.js";
export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "factdetailform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    qte_max: { type: Number, default: 0 },
  },
  data: () => ({
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    produit_id: null,
    newproduct: false,
    allproduct: false,
    service: 0,
    cs: 0,
  }),

  computed: {
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    showDetail() {
      return this.showForm;
    },
    variableadd() {
      let w = {
        Kind: 3,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: 2,
      };
      return w;
    },
    whereadd() {
      let l = [{ column: "SERVICE", value: this.service }];
      if (!this.allproduct)
        l.push({ column: "CLIENT_ID", value: this.item.tier_id });
      return l;
    },
    spec() {
      return spec;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.allproduct = !this.spec;
      this.editedItem = Object.assign({}, this.item);
      if (this.editedItem.id > 0) {
        this.produit_id = this.editedItem.produit_id;
        this.cs++;
        if (!this.editedItem.produit_id) {
          this.newproduct = true;
          this.newproduct_click();
        }
        this.produit_change(this.editedItem.produit_id);
      } else {
        if (this.service == 1 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_service_tva;
        if (this.service == 0 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_product_tva;
      }
    } else this.editedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      [
        "ref",
        "code",
        "label",
        "product_label",
        "product_code",
        "ref_ext",
        "box",
        "rack",
        "nocmd",
        "date_cmd",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    newproduct_click() {
      if (this.newproduct) {
        this.editedItem.produit_id = null;
        this.units_list = this.units;
      } else {
        this.editedItem.label = null;
        this.editedItem.pu = null;
        this.editedItem.qte = null;
        this.editedItem.fk_unit = null;
        this.units_list = [];
      }
    },
    produit_change(item, l) {
      let i;
      let list = [];
      if (l) list = l;
      this.produit_id = item;
      let produit;
      if (this.produit_id) {
        if (list.length > 0) {
          i = list.findIndex((elm) => elm.id == this.produit_id);
          if (i >= 0) {
            this.editedItem.produit_id = l ? list[i].id : list[i].produit_id;
            if (!this.editedItem.produit_id)
              this.editedItem.label = list[i].label;
            produit = list[i];

            this.editedItem.fk_unit_buy = produit.fk_unit_buy;
            this.editedItem.fk_unit_product = produit.fk_unit;
            if (this.tiertype == 1) {
              let k = this.tier_type_prix.findIndex(
                (elm) =>
                  elm.produit_id == this.editedItem.produit_id &&
                  elm.tier_type_id == this.item.tier_type_id
              );

              if (k >= 0) {
                this.prixvente = this.tier_type_prix[k].prix;
              } else this.prixvente = produit.prixvente;

              if (this.editedItem.id < 0) this.editedItem.pu = this.prixvente;
              //unite de mesure
              if (produit.fk_unit && this.editedItem.id < 0)
                this.editedItem.fk_unit = produit.fk_unit;
            }

            if (this.tiertype == 2) {
              if (this.editedItem.id < 0) {
                this.editedItem.dem_det_id = produit.dem_det_id;
              }
              this.editedItem.factor = produit.factor;
              this.prixachat = produit.prixachat;
              this.label_ext = produit.label_ext;
              this.ref_ext = produit.ref_ext;
              if (this.editedItem.id < 0) {
                this.editedItem.pu = this.prixachat;
              }

              //unite de mesure
              if (produit.fk_unit_buy && this.editedItem.id < 0)
                this.editedItem.fk_unit = produit.fk_unit_buy;
            }
            if (produit.tva_tx && this.editedItem.id < 0)
              this.editedItem.tva_tx = produit.tva_tx;

            // this.get_units();

            this.editedItem.unit_name = produit.unit_name;

            if (!this.editedItem.pu) this.$refs.pu.focus();
            else this.$refs.qte.focus();
          } else if (this.editedItem.id > 0) {
            this.allproduct = true;
            this.produit_change();
          }
        } else this.units_list = this.units;
      }

      this.editedItem.tva_tx = this.$store.state.options[0].default_product_tva;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    close() {
      this.$emit("close", this.items);
    },

    async save() {
      if (this.$refs.form.validate()) {
        let v;
        if (this.editedItem.id > 0) {
          v = {
            factdetail: {
              id: this.editedItem.id,
              pu: this.editedItem.pu,
              qte: this.editedItem.qte,
              tva_tx: this.editedItem.tva_tx,
              produit_id: this.editedItem.produit_id,
              label: this.editedItem.label,
              code: this.editedItem.code,
              comment: this.editedItem.comment,

              write_uid: this.$store.state.me.id,
            },
          };
          this.maj(UPDATE_FACT_DETAIL, v, true);
        } else {
          v = {
            factdetail: {
              fact_id: this.item.fact_id,
              pu: this.editedItem.pu,
              qte: this.editedItem.qte,
              tva_tx: this.editedItem.tva_tx,
              produit_id: this.editedItem.produit_id,
              label: this.editedItem.label,
              code: this.editedItem.code,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          v = await this.maj(CREATE_FACT_DETAIL, v, true);
          this.editedItem.id = v.createFactDetail.id;
        }
        this.close();
      }
    },
  },
};
</script>
